import React from "react";
import { DownloadLink } from "../../core/DownloadLink";
import { ImageViewer } from "../../components/ImageViewer";

import "./SelectableSkuLineItem.css";
 
export const SelectableSkuLineItem = ({ sku, selected, onClick }) => {

    const [checked, setChecked] = React.useState(selected);

    const handleChange = () => {
        setChecked(!checked);

        if (onClick) {
            onClick(sku, !checked);
        }
    };

    return (
        <div className={'selectableSkuLineItem' + (checked ? ' checked' : ' unchecked')}>

            <div className={'selector' + (checked ? ' checked' : ' unchecked')} onClick={handleChange}>
                <input type="checkbox" checked={checked} onChange={handleChange} />
            </div>

            <div>
                <div className='description' onClick={handleChange}>
                    <span className='txt'>{sku.description}</span>
                </div>

                <div className='thumbnail' onClick={handleChange}>
                    <ImageViewer src={sku.thumbnail} src2='/images/sku.png' />
                </div>
            </div>
            <div className='download-button'>
                <DownloadLink href={sku.thumbnail}/>
            </div>
        </div>
    )
}