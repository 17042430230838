import React from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card } from "../../core/Card";
import { ShoppingBreadcrumbs, OrderReference } from '../../core/Breadcrumbs';
import { Translation } from "../../core/Translation";
import { useGlobalOfferId, useOrderPlacementRouter, useLoadGlobalOfferId } from "../../infrastructure/GlobalOfferId";

import "./OrderPlacementLayout.css";

export const OrderCompleteLayout = ({ globalOfferId, offer, status, initialized, orderReference }) => {

    return (
        initialized && offer && status === 'success' &&
        <div className='orderCompleteLayout'>

            <ShoppingBreadcrumbs active={9} ignore={offer.salesChannel == 'Estimator' ? 4 : 0} />
            <OrderReference orderReference={orderReference} />
            {offer.testRollType == null ?
                <Card
                    title={
                        <Translation
                            id="3e683bed-deb2-4520-a00e-7074275aef48"
                            defaultMessage="Thank You for Uploading the Artwork for Your Order"
                            category="Card Title"
                        />
                    }
                >
                    <h3>
                        <Translation
                            id="0143adf9-5f00-480b-8324-baefae1f54ee"
                            defaultMessage="What's Next?"
                            category="Label"
                        />
                    </h3>
                    <Link to={`/offer/${globalOfferId}/summary`}>Order Confirmation</Link>
                    <ol>
                        <li>
                            <Translation
                                id="43849198-8322-40cc-b933-9a10a1fb5c27"
                                defaultMessage="We will review your artwork and prepare it for printing. Once complete, we will email you a link to approve the artwork. We will contact you if we have any questions."
                                category="Label"
                            />
                        </li>
                        <li>
                            <Translation
                                id="7a7dd0d5-5315-48af-b84c-f56854193ca6"
                                defaultMessage="Once you approve your artwork we will put your order in the production schedule."
                                category="Label"
                            />
                        </li>
                        <li>
                            <Translation
                                id="3b4410c0-28b6-42a2-8768-0385b4b11ac4"
                                defaultMessage="After you approve your artwork, it typically takes 10 business days for rollstock and 15 business days for pouches"
                                category="Label"
                            />
                        </li>
                        <li>
                            <Translation
                                id="8b178604-f847-4625-b9aa-514bead86e44"
                                defaultMessage="When your order ships we will email a shipping confirmation with a tracking number."
                                category="Label"
                            />
                        </li>
                    </ol>
                </Card>

                :

                <Card
                    title={
                        <Translation id='4f364672-ac76-48b4-b818-f665ee6ded8b' defaultMessage='Thank you for submitting your Test Roll order!' category='Card Title' />
                    }
                >
                    <h3>
                        <Translation
                            id="0143adf9-5f00-480b-8324-baefae1f54ee"
                            defaultMessage="What's Next?"
                            category="Label"
                        />
                    </h3>
                    <Link to={`/offer/${globalOfferId}/summary`}>Order Confirmation</Link>
                    <ol>
                        <li>
                            <Translation
                                id='d0468dd8-61d5-49d8-a345-7b6893c69642'
                                defaultMessage='Our Sales Support team will contact you if we have any questions.'
                                category='Label'
                            />
                        </li>
                        <li>
                            <Translation
                                id='4c9d631b-a11e-4a51-bdc1-520c2804adb6'
                                defaultMessage='If you are a prepaid customer and selected Check or ACH during payment processing we will process your order further when our Accounts Receivable team has received your payment.'
                                category='Label'
                            />
                        </li>
                        <li>
                            <Translation
                                id='6ba815c1-4d00-4162-ad3e-caeb778c3d4d'
                                defaultMessage='Test Roll orders typically ship within 10 business days.'
                                category='Label'
                            />
                        </li>
                        <li>
                            <Translation
                                id='8b178604-f847-4625-b9aa-514bead86e44'
                                defaultMessage='When your order ships we will email a shipping confirmation with a tracking number.'
                                category='Label'
                            />
                        </li>
                    </ol>
                </Card>

            }

        </div>
    );
};
