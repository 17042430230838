import React from "react";

export const GenericFormReadonlyInput = (props) => {

    return (
        <div className={props.inputstyle ? props.inputstyle : "text-action-container-textbox"}>
            <input
                data-testid={"form-input-" + props.name}
                className="control textbox"
                value={props.value}
                readOnly={true}
            />
        </div>
    );
};
