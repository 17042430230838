import { useEffect, useState, useContext } from "react";
import { useOrderPlacementRouter } from "./GlobalOfferId";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { CustomerOnHold, InvalidForPurchase } from "../pages/offer/CustomerOnHold";
import { CannotProceed } from "./CannotProceed";
import { QuoteExpiredLayout } from "../pages/offer/QuoteExpiredLayout";
import { OrderBeingProcessedNotification } from "../pages/offer/OrderBeingProcessedNotification";
import { OfferNotFound } from "../pages/offer/OfferNotFound";
import { OfferLanguageContext } from "./OfferLanguageContext";
import { ApplicationFeatures } from "./Constants";

export function OrderPlacementRouter(props) {
    const { initialized, offer, orderIntent, backwardsCompatibilityOffer, globalOfferId, status, refresh, analyticsMetadata, formattedSizeString,
        salesContact, salesforceAccount, orderReference, offerLockStatus, checkoutIntentStateId, termsConditionUrl, customerSubmitDate } = useOrderPlacementRouter();

    const location = useLocation();
    const navigate = useNavigate();
    const redirected = location?.state
    const offerLanguageContext = useContext(OfferLanguageContext);

    const [route, setRoute] = useState(<div>Loading your offer...</div>);

    useEffect(() => {
        if (!initialized) {
            return;
        }

        console.log("%c OrderPlacementRouter initialize", "color: #58a18c", location.pathname, status);

        if (!offer || status === 'notfound') {
            setRoute(<OfferNotFound />);
        }

        if (status !== 'success') {
            return;
        }

        if (offerLockStatus.expired) {
            setRoute(<CannotProceed><QuoteExpiredLayout /></CannotProceed>);
            return;
        }

        if ((backwardsCompatibilityOffer.offer.currentStateId === 100 || backwardsCompatibilityOffer.offer.currentStateId === 130) && !redirected) {
            setRoute(<Navigate to={`/offer/${globalOfferId.globalOfferId}/complete`} state={{ redirected: true }} replace />);
            return;
        }

        var hasSubmittedCheckout = orderIntent && orderIntent.id > 0;

        if (offerLockStatus.salesForceQuoteStatus && offerLockStatus.salesForceQuoteStatus.trim().toLowerCase() === 'accepted' && !hasSubmittedCheckout) {
            setRoute(<CannotProceed><OrderBeingProcessedNotification /></CannotProceed>);
            return;
        }

        if (offerLockStatus.blocked === true) {
            setRoute(<CannotProceed><CustomerOnHold offerData={backwardsCompatibilityOffer} /></CannotProceed>);
            return;
        }

        if (offerLockStatus.invalidForPurchase === true) {
            setRoute(<CannotProceed><InvalidForPurchase offerData={backwardsCompatibilityOffer} /></CannotProceed>);
            return;
        }



        var hasSubmittedOrder = orderIntent && orderIntent.id > 0 && (orderIntent.state === "PROCR" || orderIntent.state === "SBMIT" || checkoutIntentStateId === 'PENDG' || checkoutIntentStateId === 'COMPL');

        if (hasSubmittedCheckout && hasSubmittedOrder && !location.pathname.endsWith("summary") && !redirected) {
            setRoute(<Navigate to={`/offer/${globalOfferId.globalOfferId}/complete`} state={{ redirected: true }} replace />);
            return;
        }


        var isOrderPlacementEnabled = offer.checkoutDetails && offer.checkoutDetails.checkoutExperienceConfiguration && offer.checkoutDetails.checkoutExperienceConfiguration.featureSettings && (offer.checkoutDetails.checkoutExperienceConfiguration.featureSettings & ApplicationFeatures.OrderPlacementApplicationEnabled) == ApplicationFeatures.OrderPlacementApplicationEnabled;
        var routeData = {
            globalOffer: globalOfferId,
            globalOfferId: globalOfferId.globalOfferId,
            offer: offer,
            orderIntent: orderIntent,
            analyticsMetadata: analyticsMetadata,
            hasSubmittedCheckout: hasSubmittedCheckout,
            hasSubmittedOrder: hasSubmittedOrder,
            formattedSizeString: formattedSizeString,
            salesContact: salesContact,
            salesforceAccount: salesforceAccount,
            status: status,
            orderReference: orderReference,
            //refresh: refresh,
            initialized: initialized,
            backwardsCompatibilityOffer: backwardsCompatibilityOffer,
            termsConditionUrl: termsConditionUrl,
            customerSubmitDate: customerSubmitDate,
            isOrderPlacementEnabled: isOrderPlacementEnabled
        }

        // if the language has been changed once for this offer it must not change again, only on the first loading it must be set
        if (!offerLanguageContext.offerLanguage || offerLanguageContext.offerLanguage.offerId != offer.offerId)
            offerLanguageContext.setOfferLanguage({
                offerId: offer.offerId,
                sourceLanguage: offer.plant.cultureString,
                targetLanguage: offer.supportedCountry.cultureString
            });

        var handle = props.evaluate(routeData);

        if (handle?.props?.to) {
            console.log("re-route to", handle?.props?.to);
            setRoute(handle);
        }
        else {
            setRoute(handle);
        }

    }, [initialized, status]);

    useEffect(() => {
        console.log("!!OrderPlacementRouter navigation change", initialized, location.pathname);

        refresh();

    }, [navigate]);


    return status === 'success' ? route : <div>Loading your offer...</div>;
}