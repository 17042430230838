import React, { useState, useEffect, useContext, useRef } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useAccount } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import { loginRequest } from "../../authConfig";
import { Translation } from '../../core/Translation';
import { ShoppingBreadcrumbs } from '../../core/Breadcrumbs';
import { Card } from '../../core/Card';
import { RadioButton } from "../../core/RadioButton";
import { Popup } from '../../core/Popup';
import { useGlobalOfferId, useGlobalOfferContext } from '../../infrastructure/GlobalOfferId';
import OrderPlacementApi from '../../infrastructure/OrderPlacementApi';
import { SecureApiDataAccess } from '../../infrastructure/SecureApiDataAccess';
import { CapabilitiesContext } from "../../infrastructure/Contexts";
import { CapabilityName } from "../../infrastructure/Constants";

import "./ArtworkUpload.css";

export const ArtworkReview = ({ offer, orderIntent }) => {

    const globalOfferId = useGlobalOfferId();
    const { offerContext, saveContext } = useGlobalOfferContext(globalOfferId.globalOfferId);
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const capabilitiesContext = useContext(CapabilitiesContext);
    const loader = capabilitiesContext.getCapability(CapabilityName.Loader);
    const navigate = useNavigate();
    const popup = useRef(null);

    const reorderDataAccess = new SecureApiDataAccess("/api/reorder");
    const orderPlacementApi = new OrderPlacementApi("/");

    const [submitEnabled, setSubmitEnabled] = useState(!account);
    const [artworkApprovalWaived, setArtworkApprovalWaived] = useState(false);
    const [checklistArticle, setChecklistArticle] = useState(false);

    var paymentCompleted = orderIntent.paymentStateId === 'COMPL' || orderIntent.paymentStateId === 'AWCHK';

    useEffect(() => {
        const loadData = async () => {
            getArtworkChecklist(globalOfferId.globalOfferId);
        }

        if (globalOfferId.globalOfferId)
            loadData();

    }, [offer, globalOfferId]);


    const getArtworkChecklist = async (globalOfferId) => {
        await orderPlacementApi.getArticleByName(globalOfferId, "dieline-checklist-pdf")
            .then(r => {
                if (r.hasSuccess) {
                    setChecklistArticle(r.response);
                }
            })
    }

    const onRadioButtonClick = async (value) => {
        setArtworkApprovalWaived(value);
        setSubmitEnabled(true);
    }

    const leavePage = async () => {
        offerContext.reorderDetailsCheckout.artworkPageViewed = true;
        saveContext(offerContext, null);

        if (paymentCompleted) {
            await orderPlacementApi.submitForOrderPlacement(globalOfferId.globalOfferId);
        }
        loader.hide();
        document.location = `/offer/${globalOfferId.globalOfferId}`;
    }

    const handleSubmit = async () => {
        loader.show();
        offerContext.reorderDetailsCheckout = {};
        if (artworkApprovalWaived) {
            var request = {
                emailAddress: account.username
            };
            await reorderDataAccess.postAsJson(`/offer/${globalOfferId.globalOfferId}/artwork`, request)
                .then(r => {
                    if (r.hasSuccess)
                    {
                        offerContext.reorderDetailsCheckout.artworkApprovalWaived = {
                            emailAddress: account.username,
                            dateTime: r.data.submitDateTime
                        };
                        leavePage();
                    }
                })
                .catch(e => {
                    popup.current.show();
                    loader.hide();
                })
        }
        else {
            leavePage();
        }
    }

    const render = () => {
        if (offer && checklistArticle) {
            return (
                <div>
                    <ShoppingBreadcrumbs active={8} ignore={offer.salesChannel == 'Estimator' ? 4 : 0} />

                    <div className='artworkLayout'>
                        <Card
                            title={
                                <Translation
                                    id='547179e1-a4ba-4c2b-b8e5-b36e2feb4534'
                                    defaultMessage='Artwork'
                                    category='Card Title' />
                            }
                            subtitle={
                                <Translation
                                    id='6c14ae2b-b39b-4508-91d2-5d7a672fa172'
                                    defaultMessage='Please review your approved artwork for this order. You can view or download the artwork but you cannot upload new artwork for a reorder.'
                                    category='Card Sub Title' />
                            }>

                            <button className='btn btn-primary-outline' onClick={() => { document.location = `api/order/${globalOfferId.globalOfferId}/dieline/pdf`; }}>
                                <Translation id='8344101e-1add-4d46-a816-ec06799a48b2' defaultMessage='Download Dielines (Artwork Templates)' category='Label' />
                            </button>

                            <div className='flexContainer'>
                                {
                                    offer.reorderDetails.skus.map((sku, idx) => {
                                        return (
                                            <DownloadLink
                                                testIndex={idx}
                                                key={`tile${idx}`}
                                                sku={sku.description}
                                                defaultLabel={
                                                    <Translation id='fa84beb7-b8c4-436e-802b-ef7adc6be11d'
                                                        defaultMessage='Download'
                                                        category='Label'
                                                    />}
                                                id={sku.skuId}
                                                downloadUrl={sku.artwork}
                                                previewUrl={sku.thumbnail} />
                                        )
                                    })
                                }
                            </div>
                            <div>
                                <Translation id='481ede0d-b33d-48ae-be18-11f8c07fda19' defaultMessage='Reorders require final artwork approval' category='Label' />
                                <div className="artwork-waive">
                                    <UnauthenticatedTemplate>
                                        <Translation id='122e67e3-bee2-4723-9d67-d55b55363abd' defaultMessage='You can choose to waive your final artwork approval to get into the production schedule faster but you need to sign in with an authenticated account to waive this final artwork approval' category='Label' />
                                        <a onClick={(e) => {
                                            console.log("login to", document.location.href);
                                            instance.loginPopup({
                                                scopes: loginRequest.scopes,
                                                redirectUri: "/register/account",
                                                state: null
                                            })
                                                .then((loginResponse) => {
                                                    if (loginResponse) {
                                                        navigate(loginResponse.state, {
                                                            replace: true,
                                                        });
                                                    }
                                                })
                                                .catch((error) => console.log("login redirect error", error));
                                        }}>
                                            <Translation
                                                id='153c1c53-d93e-4456-b0f9-c4cca98b67bb'
                                                defaultMessage='Register or sign in to waive final artwork approval'
                                                category='Label' />
                                        </a>
                                    </UnauthenticatedTemplate>
                                    <AuthenticatedTemplate>
                                        <Translation id='546d4bcd-1abe-4686-8fcd-eda4a7b79870' defaultMessage='Do you want to waive your final artwork approval?' category='Label' />
                                        <div className="buttons-container">
                                            <RadioButton name="ArtworkApprovalWaived" value="No" onChange={() => { onRadioButtonClick(false) }}> NO </RadioButton>
                                            <RadioButton name="ArtworkApprovalWaived" value="Yes" onChange={() => { onRadioButtonClick(true) }}> YES </RadioButton>
                                        </div>
                                    </AuthenticatedTemplate>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <button
                        className="btn btn-tertiary-outline back"
                        onClick={() => {
                            navigate(`/offer/${globalOfferId.globalOfferId}`);
                        }}
                    ><span className='btn-text'><Translation id='03473ff0-be47-4bd3-9ed8-190fedf344c5' defaultMessage='Back' category='Button' /></span>
                    </button>
                    <button
                        data-testid="order-placement-upload-artwork-finish-button"
                        type="submit"
                        className='btn btn-primary pull-right'
                        disabled={!submitEnabled}
                        onClick={async () => await handleSubmit()}>
                        Submit
                    </button>
                    <Popup closeOnClickAway={true} ref={popup}>
                        <div className='contentSection'>
                            Error during artwork submit
                        </div>
                    </Popup>
                </div>
            );
        }
        return <div></div>
    }

    return render();
}


export const DownloadLink = props => {
    return (
        <div className="uploadLink buttonTile">
            <div className="content">
                <h4>{props.sku}</h4>
                <img className="previewImage" src={props.previewUrl} />
            </div>
            <div className="footer">
                <a href={props.url}>
                    <button
                        data-testid={`order-placement-download-artwork-file-button-${props.testIndex}`}
                        className="btn btn-secondary"
                        onClick={() => { document.location = props.downloadUrl; }}>
                        <span> Download </span>
                    </button>
                </a>
            </div>
        </div>
    );
}