import React, { useEffect, useContext, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Popup } from '../../core/Popup';
import { Language } from '../../infrastructure/Languages';
import { CapabilityName } from '../../infrastructure/Constants';
import { CapabilitiesContext } from '../../infrastructure/Contexts';
import { SecureApiDataAccess } from "../../infrastructure/SecureApiDataAccess";
import { QuantitySelectionComponent } from "./QuantitySelectionComponent";

export const ReorderQuantitySelection = () => {

    const popup = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const language = useContext(Language);
    const capabilitiesContext = useContext(CapabilitiesContext);
    const loader = capabilitiesContext.getCapability(CapabilityName.Loader);
    const secureApiDataAccess = new SecureApiDataAccess('/api/reorder');

    const [estimation, setEstimation] = useState();
    const [popupMessage, setPopupMessage] = useState(null);

    if (!location.state || !location.state.selectedSkus || !location.state.salesforceAccountId)
        goBack();

    const skus = location.state.selectedSkus;
    const salesforceAccountId = location.state.salesforceAccountId;
    const emailAddress = location.state.emailAddress;
    const globalOrderId = location.state.globalOrderId;

    const {
        handleSubmit,
    } = useForm();

    useEffect(() => {

        const init = async () => {
            try {
                loader.show();
                var request = {
                    salesforceAccountId: salesforceAccountId,
                    globalOrderId: globalOrderId,
                    skus: skus
                };

                var response = await secureApiDataAccess.postAsJson(`/estimate`, request);
                console.log('Estimation', response);
                setEstimation(response.data);
                loader.hide();
            }
            catch {
                setPopupMessage(language.translateLabel('5de1e4ed-4083-475e-a3ae-6316eea00a08', 'There seems to be a problem retrieving estimation results.', 'Label'));
                popup.current.show();
                setTimeout(() => {
                    goBack();
                }, 3000);
            }
            finally {
                loader.hide();
            }
        }

        if (!!salesforceAccountId)
            init();
        else
            goBack();
    }, []);

    const createOffer = async (quantity) => {
        var request = {
            EmailAddress: emailAddress,
            SalesforceAccountId: salesforceAccountId,
            Quantity: quantity,
            Skus: skus
        }
        try {
            loader.show();
            var response = await secureApiDataAccess.postAsJson("/quote", request);
            console.log(response);
            navigate(`/offer/${response.data.globalOfferId}`);
        }
        catch {
            loader.hide();
            setPopupMessage(language.translateLabel('1f8061e5-0ed9-47eb-9525-edbe28171f19', 'The order can not be created. A used material or feature might have changed or is not available anymore.', 'Label'));
            popup.current.show();
        }
    }

    const goBack = () => {
        navigate(-1);
    }

    return (
        <div>
            {estimation &&
                <QuantitySelectionComponent estimation={estimation} skuCount={skus.length} onSubmit={(quantity) => createOffer(quantity)} onCancel={() => { goBack() }} />
            }
            <Popup closeOnClickAway={true} onClose={() => { goBack() }} ref={popup}>
                <div className='contentSection'>
                    {popupMessage}
                </div>
            </Popup>
        </div>
    );
};
