import React, { useState, useContext, useEffect } from "react";
import { Row } from "../../core/Row";
import { Column } from "../../core/Column";
import { Language } from '../../infrastructure/Languages';
import { SelectableSkuLineItem } from "./SelectableSkuLineItem";

import "./SkuSelectionComponent.css";
import { ComponentType } from "../../infrastructure/Constants";

export const SkuSelectionComponent = ({ orderDetails, onSubmit, onCancel }) => {

    const language = useContext(Language);

    const [selectedSkus, setSelectedSkus] = useState([]);
    const [valid, setValid] = useState(false);

    const onSkuSelectionChanged = (sku, selected) => {
        var items = selectedSkus;
        var idx = items.findIndex(e => e == sku.approvedSkuId);
        if (selected) {
            if (idx == -1)
                items.push(sku.approvedSkuId);
        }
        else {
            if (idx > -1)
                items.splice(idx, 1);
        }

        setSelectedSkus(items);
        setValid(items.length > 0);
    }

    const onSubmitClick = async () => {
        onSubmit(selectedSkus);
    }

    return (
        <div className="sku-selection-component">
            {
                orderDetails &&
                <div>
                    <div>
                        <h4>
                            {orderDetails.convertingMethod}
                        </h4>
                        <div>
                            Your order this product last on {new Date(orderDetails.latest).toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "short", day: "numeric" })}
                        </div>
                        <div>
                            You have {orderDetails.skus.length} different artwork available to reorder sharing these product details
                        </div>
                        <div className="order-feature-list">
                            <Row>
                                <Column>
                                    <div className="feature">
                                        <div className="feature-title">
                                            Dimensions
                                        </div>
                                        <div className="feature-value">
                                            {orderDetails.dimensions}
                                        </div>
                                    </div>
                                </Column>
                                <Column>
                                    <div className="feature">
                                        <div className="feature-title">
                                            Material
                                        </div>
                                        <div className="feature-value">
                                            {orderDetails.material}
                                        </div>
                                    </div>
                                </Column>
                            </Row>
                            <Row>
                                <Column>
                                    <div className="feature">
                                        <div className="feature-title">
                                            Tear Notch
                                        </div>
                                        <div className="feature-value">
                                            {orderDetails.tearNotch}
                                        </div>
                                    </div>
                                </Column>
                                <Column>
                                    <div className="feature">
                                        <div className="feature-title">
                                            Zipper
                                        </div>
                                        <div className="feature-value">
                                            {orderDetails.zipper}
                                        </div>
                                    </div>
                                </Column>
                            </Row>
                            <Row>
                                <Column>
                                    <div className="feature">
                                        <div className="feature-title">
                                            Hang Hole
                                        </div>
                                        <div className="feature-value">
                                            {orderDetails.hangHole}
                                        </div>
                                    </div>
                                </Column>
                                <Column>
                                    <div className="feature">
                                        <div className="feature-title">
                                            Valve
                                        </div>
                                        <div className="feature-value">
                                            {orderDetails.valve}
                                        </div>
                                    </div>
                                </Column>
                            </Row>
                            <Row>
                                <Column>
                                    <div className="feature">
                                        <div className="feature-title">
                                            Rounded Corners
                                        </div>
                                        <div className="feature-value">
                                            {orderDetails.roundedCorners}
                                        </div>
                                    </div>
                                </Column>
                                <Column>
                                    <div className="feature">
                                        <div className="feature-title">
                                            Seal Width
                                        </div>
                                        <div className="feature-value">
                                            {orderDetails.sealWidth}
                                        </div>
                                    </div>
                                </Column>
                            </Row>
                            <Row>
                                <Column>
                                    <div className="feature">
                                        <div className="feature-title">
                                            Color spec
                                        </div>
                                        <div className="feature-value">
                                            {orderDetails.colorSpec}
                                        </div>
                                    </div>
                                </Column>
                                <Column>
                                    <div className="feature">
                                        <div className="feature-title">
                                            Eyemark Placement
                                        </div>
                                        <div className="feature-value">
                                            {orderDetails.eyemark}
                                        </div>
                                    </div>
                                </Column>
                                </Row>
                                { orderDetails.componentTypeId === ComponentType.Rollstock &&
                                <Row>
                                    <Column>
                                        <div className="feature">
                                            <div className="feature-title">
                                                Core Size
                                            </div>
                                            <div className="feature-value">
                                                {orderDetails.core}
                                            </div>
                                        </div>
                                    </Column>
                                        <Column>
                                            <div className="feature">
                                                <div className="feature-title">
                                                    Seal Wrap Type
                                                </div>
                                                <div className="feature-value">
                                                    {orderDetails.sealWrapType}
                                                </div>
                                            </div>
                                    </Column>
                                </Row>
                            }
                        </div>
                    </div>
                    <div className="artwork-selection">
                        <div className="feature-title">
                            Please select the artwork to reorder
                        </div>
                        Artwork from order {orderDetails.globalOrderId}
                        {
                            orderDetails.skus.map((sku, idx) => {
                                return (
                                    <div className="sku-list-component">

                                        <div>
                                            <SelectableSkuLineItem key={`sku${idx}`} sku={sku} onClick={(sku, selected) => { onSkuSelectionChanged(sku, selected) }} />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {
                        orderDetails.additionalSkus && orderDetails.additionalSkus.length > 0 &&
                        <div className="artwork-selection">
                            Artwork from different order
                            {
                                orderDetails.additionalSkus.map((sku, idx) => {
                                    return (
                                        <div className="sku-list-component">

                                            <div>
                                                <SelectableSkuLineItem key={`sku${idx}`} sku={sku} onClick={(sku, selected) => { onSkuSelectionChanged(sku, selected) }} />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }

                    <button data-testid="sku-selection-button-next" id='btnNext' className='btn btn-secondary pull-right next' disabled={!valid} onClick={() => { onSubmitClick() }}><span className='btn-text'>{language.translateLabel('20fdf7cc-ff08-4444-96e3-8b79cff46628', 'Continue', 'Button')}</span></button>
                    {onCancel &&
                        <button className='btn btn-tertiary-outline back' onClick={() => { onCancel(); }}><span className='btn-text'>{language.translateLabel('48cda936-8f88-4703-a02c-69648eb3fa2a', 'Back', 'Button')}</span></button>
                    }
                </div>
            }
        </div >
    )
}