import React, { useEffect, useRef, useContext, useState } from 'react';
import { Info } from 'react-feather';
import { useNavigate } from "react-router-dom";
import { Translation } from "../../core/Translation";
import { List } from '../../core/List';
import { Language } from '../../infrastructure/Languages';
import { MyAccountPageHeaderOptions } from '../../core/MyAccountPageHeaderOptions'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsalAuthentication, useMsal, useAccount } from "@azure/msal-react";
import { SecureApiDataAccess } from "../../infrastructure/SecureApiDataAccess";
import { CapabilitiesContext } from "../../infrastructure/Contexts";
import { CapabilityName } from "../../infrastructure/Constants";
import { loginRequest, protectedResources } from "../../authConfig";
import { Login } from "./Login";


import "./MyOrders.css";


export const MyOrders = () => {

    const language = useContext(Language);
    const capabilitiesContext = useContext(CapabilitiesContext);
    const loader = capabilitiesContext.getCapability(CapabilityName.Loader);
    const list = useRef(null);

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const userDataAccess = new SecureApiDataAccess("/api/user");
    const navigate = useNavigate();

    const [loaded, setLoaded] = useState(false);
    const [salesforceAccountId, setSalesforceAccountId] = useState(null);
    const getOrdersByAccountIdUrl = `api/orders/account/${salesforceAccountId}`;

    useEffect(() => {

        if (!account) {
            console.log("no user we will redirect to login page")
            //window.location.href = decodeURIComponent('/login');
        }
        const init = async () => {
            var user = await getUser();
            // var salesforceAccount = await getSfAccount(user.data.salesforceAccountId);

            if (user.hasSuccess === true) {
                console.log("USER EXISTS LETS CONTINUE:::::")
                setLoaded(true);

            }
            //if (salesforceAccount.hasSuccess === true) {
            //    console.log("SF ACCOUNT IS HERE LETS CONTINUE:::::")
            //}

        }
        init();
    }, [account]);


    const getUser = async () => {
        const response = await instance.acquireTokenSilent({
            scopes: [...protectedResources.shopApp.scopes],
            ...loginRequest,
            account: account,
        });

        if (!response.accessToken) {
            throw new Error("Could not retrieve access token.");
        }
        var user = await userDataAccess.get(
            `/search?emailAddress=${encodeURIComponent(
                account.username
            )}&identityProviderAccountId=${encodeURIComponent(account.idTokenClaims.oid)}`,
            response.accessToken,
            {
                throwOnNonSuccess: false
            }
        );

        if (user.hasSuccess) {
            console.log("A user was found", user);
            setSalesforceAccountId(user.data.salesforceAccountId);
            return user;

        } else {

            console.log("Didn't find a user.", user.statusCode);
            return null;
        }
    }


    return (
        <div>
            {loaded &&
                <AuthenticatedTemplate>
                    <MyAccountPageHeaderOptions salesforceAccountId={salesforceAccountId} selectedHeaderNumber={1} />
                    <div>
                        <p style={{ "fontWeight": "normal" }}><Translation id='0cbf2d63-e671-438b-a2e0-100c0031b091' defaultMessage='For a detailed order status or shipment information please reach out to your ePac Sales Support Specialist.' category='Label' /></p>
                        <p style={{ "fontStyle": "italic" }}><Translation id='43d57db4-4aba-4779-ace7-86a951984036' defaultMessage='Orders are eligible for reorder once artwork has been approved AND ePac has manufactured the order' category='Label' /></p>

                    </div>
                    <div className="myOrdersTable">

                        <List
                            ref={list}
                            controller={getOrdersByAccountIdUrl}
                            action='/'
                            sortColumn='globalOrderId'
                            sortDesc={true}
                            headers={[
                                { column: 'globalOrderId', title: 'Order #', sortable: true, filterable: true, className: 'col-1 col-sm-1' },
                                { column: 'customerOrderStatus', title: 'Order Status', sortable: false, filterable: false, className: 'col-2 col-sm-2', info: true, helpContext: "my-account-customer-order-status" },
                                { column: 'orderDate', title: 'Order Date', sortable: false, filterable: false, className: 'col-1 col-sm-1', onRenderFilter: () => { } },
                                { column: 'convertingMethod', title: 'Converting Method', sortable: false, filterable: false, className: 'col-2 col-sm-2' },
                                { column: 'formattedDimensions', title: 'Dimensions', sortable: false, filterable: false, className: 'col-1 col-sm-1' },
                                { column: 'artApprovalDate', title: 'Art Approval Date', sortable: false, filterable: false, className: 'col-1 col-sm-1', info: true, helpContext: "my-account-art-approval-date" },
                                { column: 'slaDate', title: 'Shipped Date', sortable: false, filterable: false, className: 'col-1 col-sm-1', info: true, helpContext: "my-account-sla_date" },
                                { column: 'action', title: '', sortable: false, filterable: false, className: 'action col-1 col-sm-1' },
                            ]}
                            onRenderRow={(column, item, header) => {
                                switch (column) {

                                    case 'globalOrderId':
                                        var url = "epacflexibles.com";
                                        // TODO: when order summary page is complete, redirect there
                                        /*            return (<a href={url} target="_blank" rel="noopener noreferrer" >{item.globalOrderId}</a>);*/
                                        return (item.globalOrderId);

                                    case 'artApprovalDate':
                                        var date = item.artApprovalDate ? new Date(item.artApprovalDate).toLocaleDateString() : "-";
                                        return date;

                                    case 'slaDate':
                                        var date = item.slaDate ? new Date(item.slaDate).toLocaleDateString() : "-";
                                        return date;

                                    case 'orderDate':
                                        var date = item.orderDate ? new Date(item.orderDate).toLocaleDateString() : "-";
                                        return date;

                                    case 'customerOrderStatus':
                                        return language.translateDomain('bf586470-52ae-43e9-82e9-11129eafae24', item.orderStatusId, item.customerOrderStatus, 'Order Status');

                                    case 'action':
                                        if (item.customerOrderStatus === "Pending Submission") {
                                            var url = `${process.env.REACT_APP_ORDER_PLACEMENT_BASE_URL}offer/${item.globalOfferId}`;
                                            return (
                                                <div>
                                                    <span className="submitAction"><Info className='icon' /></span>
                                                    <a href={url} target="_blank" rel="noopener noreferrer" title="Finish Submitting Your Order">Finish</a>
                                                </div>);
                                        }
                                        else if (item.customerOrderStatus === "Shipped & Invoiced") {
                                            return (<a onClick={() => navigate(`/myorders/${item.globalOrderId}/reorder`, {
                                                state: {
                                                    emailAddress: account.username,
                                                    salesforceAccountId: salesforceAccountId
                                                }
                                            })} rel="noopener noreferrer" >Reorder</a>);
                                        }
                                        return "  ";

                                    default:
                                        return item[column] != null ? <span>{item[column]}</span> : <span>&nbsp;</span>;
                                        return <div></div>
                                }
                            }}
                        />




                    </div>
                </AuthenticatedTemplate>
            }
            <UnauthenticatedTemplate>
                <Login />
            </UnauthenticatedTemplate>
        </div>
    )
}



