import React, { useEffect, useContext, useState } from "react";
import { ShoppingBreadcrumbs } from "../core/Breadcrumbs";
import { Tabs, Tab } from "../core/Tabs";
import { Page } from "../core/Page";
import { TabContent, ActiveTab } from "../components/TabContent";
import { useNavigate } from "react-router-dom";
import { useCheckoutContext } from "../infrastructure/CheckoutContext";
import { ContextHelp } from "../core/ContextHelp";
import { SkuQuantitySelection } from "../components/SkuQuantitySelection";
import { useForm } from "react-hook-form";
import { Language } from '../infrastructure/Languages';
import { CapabilityName } from '../infrastructure/Constants';
import { CapabilitiesContext } from '../infrastructure/Contexts';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsalAuthentication, useMsal, useAccount } from "@azure/msal-react";
import { loginRequest, protectedResources } from "../authConfig";
import { SecureApiDataAccess } from "../infrastructure/SecureApiDataAccess";
import { useUserContext } from '../infrastructure/UserContext';
import { Screen } from '../infrastructure/Screen';
import { PendingRegistration } from "./registration/PendingRegistration";
import { useTracking } from "react-tracking";
import { useCookies } from 'react-cookie';
import { NotificationContext } from '../capabilities/NotificationContext';

import "./Features.css";
import "./Skus.css";
import { HookFormNumberInput } from "../core/HookFormNumberInput";

export const SkusPageProtected = () => {

    const navigate = useNavigate();
    const language = useContext(Language);
    const { trackEvent } = useTracking({ page: "SkusPage" });
    const { userRegion, userLanguage, updatePreviousCart } = useUserContext();
    const { setIsProceedingToCheckout } = useContext(NotificationContext);

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const capabilitiesContext = useContext(CapabilitiesContext);
    const loader = capabilitiesContext.getCapability(CapabilityName.Loader);
    const [cookies, setCookie] = useCookies(['visitorId']);
    const [visitorId, setVisitorId] = useState(null);

    const {
        handleSubmit,
        control,
        getValues,
        reset,
        formState: { errors, isValid },
    } = useForm({
        defaultValues: {
            skuCount: "",
            quantity: "",
        },
        mode: "onChange",
    });

    const { product,
        material,
        dimensions,
        convertingMethod,
        feature,
        skuCount: customerSkuCount,
        prices,
        selectedSkuQuantityBreakdown,
        updateCheckoutFlag,
        updateSelectedSkuQuantityBreakdown,
        updateSkuCount,
        updatePrices,
        reset: resetContext,
        getCart,
        updateSelectedSkuCountAndQuantity,
        selectedSkuCount,
        selectedQuantity
    } = useCheckoutContext();

    const [priceQuantityBreakdown, setPriceQuantityBreakdown] = useState(prices ? prices : []);
    const [selectedPriceQuantityBreakdown, setSelectedPriceQuantityBreakdown] = useState(selectedSkuQuantityBreakdown);
    const [quantity, setQuantity] = useState('');
    // const [selectedQuantity, setSelectedQuantity] = useState(null);
    const [userSelectedQuantity, setUserSelectedQuantity] = useState(0);
    const [skuCount, setSkuCount] = useState(customerSkuCount ? customerSkuCount : 0);
    const [loaded, setLoaded] = useState(true);
    const [showDisplay, setShowDisplay] = useState(true);
    const checkoutDataAccess = new SecureApiDataAccess("/api/checkout");
    const userDataAccess = new SecureApiDataAccess("/api/user");
    const [minOrderQuantity, setMinOrderQuantity] = useState(1);
    const [minSellablePrice, setMinSellablePrice] = useState();

    // const [skuMessage, setSkuMessage] = useState(() => {
    //     const phrase = "";
    //     return phrase;
    // });
    const estimateDataAccess = new SecureApiDataAccess("/api/estimates");

    const [registrationPending, setRegistrationPending] = useState(false);

    const minSkuCount = convertingMethod && convertingMethod.skuMetadata && convertingMethod.skuMetadata.minSkuCount ? convertingMethod.skuMetadata.minSkuCount : 1;
    const maxSkuCount = convertingMethod && convertingMethod.skuMetadata && convertingMethod.skuMetadata.maxSkuCount ? convertingMethod.skuMetadata.maxSkuCount : 99;
    const minSkuQuantity = convertingMethod && convertingMethod.skuMetadata && convertingMethod.skuMetadata.minSkuQuantity ? convertingMethod.skuMetadata.minSkuQuantity : 0;
    // const [timeoutRef, setTimeoutRef] = useState(null);
    const [canRecalculatePrices, setCanRecalculatePrices] = useState(false);
    const [canProceed, setCanProceed] = useState(false);

    useEffect(() => {

        console.log("!! sku quantity", selectedSkuCount, selectedQuantity);
        reset({
            quantity: selectedQuantity,
            skuCount: selectedSkuCount
        });

        if (account) {

            const getPricingForLoggingInUser = async () => {

                var cart = getCart();
                console.log('cart', cart);
                updatePreviousCart(cart);

                if (selectedSkuCount > 0 && selectedQuantity > 0) {
                    await getEstimate();
                }

            }
            getPricingForLoggingInUser();
        }
        else {
            // if (selectedSkuQuantityBreakdown && customerSkuCount) {
            // adjustSkuCountAndMessage(customerSkuCount);
            // setSelectedQuantity(selectedSkuQuantityBreakdown.orderedQty);
            setPriceQuantityBreakdown(prices ? prices : []);
            setSelectedPriceQuantityBreakdown(selectedSkuQuantityBreakdown);
            // setSkuQuantityMessage(getSkuQuantityMessage(selectedSkuQuantityBreakdown.orderedQty));
            // }

            Screen.scrollToElement('layout');
        }

        // adjustSkuCountAndMessage(customerSkuCount);
        var id = cookies.visitorId;
        if (id) {
            setVisitorId(id);
        }


    }, []);


    const getAdditionalQuantitiesForSavings = (quantity) => {

        //25,50,75,100
        var additionalQuantity1 = parseInt(quantity * 1.25);   //25%
        var additionalQuantity2 = parseInt(quantity * 1.5);    //50%
        var additionalQuantity3 = parseInt(quantity * 1.75);   //75%
        var additionalQuantity5 = parseInt(quantity * 2);      //100%
        return [quantity.toString(), additionalQuantity1.toString(), additionalQuantity2.toString(), additionalQuantity3.toString(), additionalQuantity5.toString()];

    }

    const calculateSavings = (originalPrice, price2) => {
        var percentSavings = parseFloat(((originalPrice - price2) / originalPrice) * 100).toFixed(0);
        return Math.abs(percentSavings);

    }

    const formatQuantities = (quantityBreaks, minSellablePrice) => {
        var original = quantityBreaks[0].price.unitPrice.amount;

        var formattedQuantities = [];
        minSellablePrice = minSellablePrice ? parseFloat(minSellablePrice) : 0;
        quantityBreaks.forEach((quantity) => {
            if (minSellablePrice >= 0) {
                var canSell = parseFloat(quantity.price.unitPrice.amount * quantity.orderedQty) >= minSellablePrice;
                var savingsPercent = calculateSavings(original, quantity.price.unitPrice.amount);
                quantity.canSell = canSell;
                quantity.savingsPercentage = savingsPercent;
            }
            formattedQuantities.push(quantity);
        })
        return formattedQuantities;
    }

    const getEstimateCalculations = async (quantity, skuCount1) => {
        updateSkuCount(skuCount1);
        var priceBreakdown = priceQuantityBreakdown.find(x => x.orderedQty === parseInt(quantity));

        // get additional quantites for customers to see potential savings
        var quantityIncludingAdditionalQuantitiesForSavings = getAdditionalQuantitiesForSavings(quantity);


        // if (!priceBreakdown || skuCount1 != skuCount) {
        if (!priceBreakdown || canRecalculatePrices) {
            loader.show();
            //try {
            setLoaded(false);

            var artwork = {
                "Skus": skuCount1,
                "ColorSpecs": [
                    {
                        "ColorSpecId": 1, //default
                        "StructureSpecs": [{ "Id": material.id }]
                    }
                ]
            }

            var packagingType = {
                'convertingMethod': convertingMethod.convertingMethodId,
                'componentType': convertingMethod.componentTypeId,
                'width': dimensions.selectedDims.width,
                'length': dimensions.selectedDims.length,
                'gusset': dimensions.selectedDims.gusset ? dimensions.selectedDims.gusset : 0,
                'lengthMeasureUnitId': dimensions.selectedDims.measureUnitId,
                'widthMeasureUnitId': dimensions.selectedDims.measureUnitId
            };

            var featureRequest = {
                'hangHoleId': feature.hangHoles.id,
                'tearNotchId': feature.tearNotches.id,
                'zipperId': feature.zippers.id,
                'ventId': feature.vents.id,
                'doubleCutId': feature.doubleCuts.id,
                'roundedCornerId': feature.roundedCorners.id,
                'sealWidthId': feature.sealWidths.id,
                'gussetTypeId': feature.gussetTypes.id,
                'structureSpecs': [{ "id": material.id }]
            };


            var materialRequest = {
                StructureSpecs: [material.id]
            }

            var request = {
                'cultureString': userRegion,
                'supportedCountryCultureString': userLanguage,
                'productId': product && product.productId ? product.productId : null,
                'quantityBreaks': quantityIncludingAdditionalQuantitiesForSavings,
                'packagingType': packagingType,
                'artwork': artwork,
                'packagingFeature': [featureRequest],
                'material': materialRequest
            };

            const acquireTokenResponse = await instance.acquireTokenSilent({
                scopes: [...protectedResources.shopApp.scopes],
                ...loginRequest,
                account: account,
            });

            if (!acquireTokenResponse.accessToken) {
                throw new Error("Could not retrieve access token.");
            }

            console.log("####### CALC REQUEST: ", request);

            var response = await estimateDataAccess.postAsJson('/', request, acquireTokenResponse.accessToken);

            console.log("####### CALC RESULTS: ", request, response);
            var estimates = response.data.estimates;
            var estimate = estimates.estimate[0];
            var formattedEstimatesIncludingSavings = formatQuantities(estimates.estimate, response.data.minSellablePrice);
            var minSellable = response.data.minSellablePrice ? parseFloat(response.data.minSellablePrice) : 0;
            setMinSellablePrice(minSellable);
            estimate.auditId = response.auditId;
            estimate.currencyConversionRate = response.currencyConversionRate;
            setCanProceed(parseFloat(estimate.price.totalPrice.amount) >= minSellable);

            var priceBreakdown = [];
            formattedEstimatesIncludingSavings.forEach((est) => {
                priceBreakdown.push(est);
            })
            priceBreakdown.sort((a, b) => (a.orderedQty > b.orderedQty) ? 1 : -1);

            setPriceQuantityBreakdown(priceBreakdown);
            setSelectedPriceQuantityBreakdown(priceBreakdown[0]);
            updateSelectedSkuQuantityBreakdown(priceBreakdown[0]);

            updatePrices(priceBreakdown);

            setLoaded(true);

            var quoteCount = priceBreakdown.length;

            trackEvent({ ecommerce: null });  // Clear the previous ecommerce object.
            trackEvent({
                event: 'add_to_cart',
                ecommerce: {
                    items: [
                        {
                            item_id: product.productId,
                            item_name: product.productName,
                            currency: estimate.price.totalPrice.currency,
                            index: quoteCount,            //the nth quote added, starting at 1
                            unit_price: estimate.price.unitPrice.amount,
                            item_category: product.industryName,  //what packaging category
                            item_category2: product.industryCategoryName,      //what packaging sub-category
                            item_category3: convertingMethod.componentTypeName,     //pouch type
                            item_category4: dimensions.selectedDims.width + " x " + dimensions.selectedDims.length,      // width x length
                            price: estimate.price.totalPrice.amount,
                            quantity: quantity,
                            skuCount: skuCount1
                        }
                    ]
                },
                userId: visitorId
            });
            /*
            }
            catch (ex) {
                console.error(ex);
            }
            */
            loader.hide();
        }
        else {
            // if quantity already exists in current breakdowns, automatically select
            onQuantitySelected(quantity)
        }

    }

    const getMinOrderQuantity = (skuCount) => skuCount * minSkuQuantity;

    const getEstimate = async () => {
        await getEstimateCalculations(parseInt(getValues("quantity")), parseInt(getValues("skuCount")));

        trackEvent({
            event: 'ordering_tool_interactions',
            eventProps: {
                'step': 'order_shopping_5',        // order_shopping_5
                'product_category': product.industryName,        // e.g. Food & Beverage
                'product_sub_category': product.industryCategoryName, // E.g. Dry Mix
                'selection': "pouch_quantity",                            //e.g.skus, pouch_quantity
                'element': "add_to_quote"
            },
            userId: visitorId
        });
    }

    const onQuantitySelected = (data) => {
        if (selectedQuantity > 0) {
            var prevPriceBreakdown = priceQuantityBreakdown.find(x => x.orderedQty === parseInt(getValues("quantity")));

            // Send GA event for previously selected quantity being changed/removed
            trackEvent({
                event: 'remove_from_cart',
                ecommerce: {
                    items: [
                        {
                            item_id: product.productId,
                            item_name: product.productName,
                            currency: prevPriceBreakdown.price.totalPrice.currency,
                            index: prevPriceBreakdown.breakId,            //the nth quote added, starting at 1
                            unit_price: prevPriceBreakdown.price.unitPrice.amount,
                            item_category: product.industryName,  //what packaging category
                            item_category2: product.industryCategoryName,      //what packaging sub-category
                            item_category3: convertingMethod.componentTypeName,     //pouch type
                            item_category4: dimensions.selectedDims.width + " x " + dimensions.selectedDims.length,      // width x length
                            price: prevPriceBreakdown.price.totalPrice.amount,
                            quantity: selectedQuantity,
                            skuCount: skuCount
                        }
                    ]
                },
                userId: visitorId
            });
        }

        setUserSelectedQuantity(data);

        var priceBreakdown = priceQuantityBreakdown.find(x => x.orderedQty === parseInt(data));

        setSelectedPriceQuantityBreakdown(priceBreakdown);
        updateSelectedSkuQuantityBreakdown(priceBreakdown);

        trackEvent({
            event: 'ordering_tool_interactions',
            eventProps: {
                'step': 'order_shopping_5',        // order_shopping_5
                'product_category': product.industryName,        // e.g. Food & Beverage
                'product_sub_category': product.industryCategoryName, // E.g. Dry Mix
                'selection': "pouch_quantity",                            //e.g.skus, pouch_quantity
                'element': data
            },
            userId: visitorId
        });

        // setSkuQuantityMessage(getSkuQuantityMessage(data));

        if (priceBreakdown) {
            trackEvent({
                event: 'add_to_cart',
                ecommerce: {
                    items: [
                        {
                            item_id: product.productId,
                            item_name: product.productName,
                            currency: priceBreakdown.price.totalPrice.currency,
                            index: priceBreakdown.breakId,            //the nth quote added, starting at 1
                            unit_price: priceBreakdown.price.unitPrice.amount,
                            item_category: product.industryName,  //what packaging category
                            item_category2: product.industryCategoryName,      //what packaging sub-category
                            item_category3: convertingMethod.componentTypeName,     //pouch type
                            item_category4: dimensions.selectedDims.width + " x " + dimensions.selectedDims.length,      // width x length
                            price: priceBreakdown.price.totalPrice.amount,
                            quantity: priceBreakdown.orderedQty,
                            skuCount: skuCount
                        }
                    ]
                },
                userId: visitorId
            });
        }

        setCanProceed(priceBreakdown.canSell);
    }

    const onQuantityRemoved = (data) => {
        var items = JSON.parse(JSON.stringify(priceQuantityBreakdown));
        setPriceQuantityBreakdown(null);

        var idx = items.findIndex(e => e.orderedQty === data);
        var removedItem = null;
        if (idx !== -1) {
            removedItem = items[idx];
            items.splice(idx, 1);
        }

        setPriceQuantityBreakdown(items);
        setQuantity(0);
        // setSkuQuantityMessage(getSkuQuantityMessage(0));
        updateSelectedSkuQuantityBreakdown(items);

        trackEvent({
            event: 'ordering_tool_interactions',
            eventProps: {
                'step': 'order_shopping_5',        // order_shopping_5
                'product_category': product.industryName,        // e.g. Food & Beverage
                'product_sub_category': product.industryCategoryName, // E.g. Dry Mix
                'selection': "pouch_quantity_removed",                            //e.g.skus, pouch_quantity
                'element': data
            },
            userId: visitorId
        });

        trackEvent({ ecommerce: null });  // Clear the previous ecommerce object.
        trackEvent({
            event: 'remove_from_cart',
            ecommerce: {
                items: [
                    {
                        item_id: product.productId,
                        item_name: product.productName,
                        currency: removedItem?.price.totalPrice.currency,
                        index: idx + 1,            //the nth quote added, starting at 1
                        unit_price: removedItem?.price.unitPrice.amount,
                        item_category: product.industryName,  //what packaging category
                        item_category2: product.industryCategoryName,      //what packaging sub-category
                        item_category3: convertingMethod.componentTypeName,     //pouch type
                        item_category4: dimensions.selectedDims.width + " x " + dimensions.selectedDims.length,      // width x length
                        price: removedItem?.price.totalPrice.amount,
                        quantity: data
                    }
                ]
            },
            userId: visitorId
        });
    }


    const onSignupClicked = async (e) => {
        trackEvent({
            event: 'ordering_tool_interactions',
            eventProps: {
                'step': 'order_shopping_5',        // order_shopping_5
                'product_category': product.industryName,        // e.g. Food & Beverage
                'product_sub_category': product.industryCategoryName, // E.g. Dry Mix
                'selection': "skus, pouch_quantity",
                'element': "proceed_to_checkout"
            },
            userId: visitorId
        });
        trackEvent({
            event: 'ordering_tool_selections',
            eventProps: {
                'step': 'order_shopping_5',        // order_shopping_5
                'product_category': product.industryName,        // e.g. Food & Beverage
                'product_sub_category': product.industryCategoryName, // E.g. Dry Mix
                'selection': customerSkuCount + " | " + selectedPriceQuantityBreakdown.orderedQty      //e.g.2|20000
            },
            userId: visitorId
        });

        loader.show();

        updateSelectedSkuCountAndQuantity(getValues("quantity"), getValues("skuCount"));

        updateCheckoutFlag(true);

        instance.loginRedirect({
            scopes: loginRequest.scopes,
            redirectUri: "/sign-in",
        })
        .catch((error) => console.log(error));
    }

    const onSubmitOffer = async (data, errors) => {

        setShowDisplay(false);
        setIsProceedingToCheckout(true);
        loader.show();

        trackEvent({
            event: 'ordering_tool_selections',
            eventProps: {
                'step': 'order_shopping_5',        // order_shopping_5
                'product_category': product.industryName,        // e.g. Food & Beverage
                'product_sub_category': product.industryCategoryName, // E.g. Dry Mix
                'selection': customerSkuCount + " | " + selectedPriceQuantityBreakdown.orderedQty      //e.g.2|20000
            },
            userId: visitorId
        });

        var offer = {
            auditId: selectedPriceQuantityBreakdown.auditId,
            currencyConversionRate: selectedPriceQuantityBreakdown.currencyConversionRate ? selectedPriceQuantityBreakdown.currencyConversionRate : 1,
            skuCount: customerSkuCount,
            calculations: selectedPriceQuantityBreakdown,
            convertingMethod: convertingMethod,
            dimensions: dimensions.selectedDims,
            //core: corePayload,
            packagingFeature: feature,
            product: product,
            material: material,
            culture: language.getTargetCulture()
        };
        const response = await instance.acquireTokenSilent({
            scopes: [...protectedResources.shopApp.scopes],
            ...loginRequest,
            account: account,
        });

        if (!response.accessToken) {
            throw new Error("Could not retrieve access token.");
        }

        var user = await userDataAccess.get(
            `/search?emailAddress=${encodeURIComponent(
                account.username
            )}&identityProviderAccountId=${encodeURIComponent(account.idTokenClaims.oid)}`,
            response.accessToken
        );

        if (user.hasSuccess && user.data.registrationStatusId === "COMPL") {
            offer.salesforceAccountId = user.data.salesforceAccountId
            offer.cultureString = userRegion;
            offer.visitorId = visitorId;

            console.log("########### SEND REQUEST FOR OFFER: ", offer, account);
            console.log(JSON.stringify(offer));

            var checkoutResponse = await checkoutDataAccess.postAsJson("/", offer, response.accessToken);

            if (checkoutResponse.hasSuccess) {
                loader.hide();
                setIsProceedingToCheckout(false);
                resetContext();

                navigate(`/offer/${checkoutResponse.data.globalOfferId}`);
            }
        }
        else {
            setRegistrationPending(true);
            setShowDisplay(true);
            loader.hide();
            setIsProceedingToCheckout(false);
        }
    }

    const onBackButtonClicked = () => {
        trackEvent({
            event: 'ordering_tool_interactions',
            eventProps: {
                'step': 'order_shopping_5',        // order_shopping_1
                'product_category': product.industryName,        // e.g. Food & Beverage
                'product_sub_category': product.industryCategoryName, // E.g. Dry Mix
                'element': 'back', // e.g. choose_this_style, information, photo_scroll, back
            },
            userId: visitorId
        });
        // getSkuCountMessage(skuCount)
        if (isValid){
            updateSelectedSkuCountAndQuantity(parseInt(getValues("quantity")), parseInt(getValues("skuCount")));
        }

        navigate(`/${userRegion}/group/${product.productGroupId}/convertingMethod/${convertingMethod.convertingMethodId}/material/${material.id}/features`);
    }


    const getNextButton = () => {
        return (
            <button type="button" data-testid="button-proceed-to-checkout" className="btn btn-secondary pull-right next" disabled={!canProceed} onClick={(e) => { onSubmitOffer(e) }}><span className='btn-text'>{language.translateLabel('67faab93-8201-40e5-9261-1db130febea3', 'Proceed to Checkout', 'Button')}</span></button>
        );
    }

    const setGoogleAnalyticsContext = (name) => {
        var ga = {
            step: 'order_shopping_5',
            industryName: product.industryName,
            industryCategoryName: product.industryCategoryName,
            selection: name,
            userId: visitorId
        }
        return ga;
    }

    return (
        <div className='skuPage'>
            <ShoppingBreadcrumbs active={4} />

            {showDisplay ?
                registrationPending ?
                    <PendingRegistration /> :
                    <Page
                        className="skusPage"
                        onRenderHeader={() => {
                            return (
                                <Tabs>
                                    <Tab title={language.translateLabel('04ea9f38-1c48-4a34-9f44-ebbefbcc6436', 'Your Selections', 'Tab')}><TabContent activeTab={ActiveTab.Sku} /></Tab>
                                </Tabs>
                            );
                        }}
                    >
                        <form onSubmit={handleSubmit(onSubmitOffer)} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}>
                            <div className='numberEntry'>
                                <div className='section'>
                                    <div className='inline'>
                                        <h3>{language.translateLabel('895bb603-71f7-4f81-b4e5-5fa7800d1175', 'How many different SKUs will be using this package?')}
                                            <span className="required">*</span>
                                            <ContextHelp gaContext={setGoogleAnalyticsContext("How many different SKUs will be using this package")}></ContextHelp>{" "}                                        </h3>
                                        <HookFormNumberInput
                                            control={control}
                                            name="skuCount"
                                            placeholder={0}
                                            min={minSkuCount}
                                            max={maxSkuCount}
                                            // onChange={onSkuCountChangeWithDelay}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: language.translateLabel('d024dd66-bbf8-4893-81b3-f675dfbda959', 'A sku count is required')
                                                },
                                                validate: {
                                                    skuCountCheck: (skuCount) => {
                                                        if (skuCount > maxSkuCount) {
                                                            return language.translateLabel('9fa85799-642d-4709-abff-7f557766b627', 'The maximum sku count is:') + " " + maxSkuCount;
                                                        }

                                                        if (skuCount < minSkuCount) {
                                                            return language.translateLabel('677b05f8-c632-4884-83e2-7d633dfa512c', 'The minimum sku count is:') + " " + minSkuCount;
                                                        }

                                                        return true;
                                                    }
                                                },
                                                deps: ["quantity"]
                                            }}
                                            onBlur={(e) => {
                                                setCanRecalculatePrices(true);
                                            }}
                                            inputstyle="text-action-container-textbox-sm"
                                        />

                                    </div>
                                    <p className="notice small">{language.translateLabel('39fd12b7-b7cc-45e8-b5af-87f2609a2398', 'Changing the number in the SKU field will recalculate your prices.')}</p>

                                    {errors && errors.skuCount && <p className="alert">{errors.skuCount.message}</p>}
                                </div>

                                <div className='section'>
                                    <div className='inline'>
                                        <h3>{language.translateLabel('a231544b-e8f3-483d-a1fb-3448c95690e5', 'How many pouches would you like?')}
                                            <span className="required">*</span>
                                        </h3>
                                        <div className="text-action-container">
                                            <HookFormNumberInput
                                                control={control}
                                                name="quantity"
                                                placeholder={"Ex: 10,000"}
                                                min={minOrderQuantity}
                                                // onChange={onQuantityChange}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: "You must enter a valid quantity"
                                                    },
                                                    pattern: {
                                                        value: /^\d+$/,
                                                        message: "Sku quantity must be numeric",
                                                    },
                                                    validate: {
                                                        quantityCheck: (qty) => {
                                                            var parsedSkuCount = parseInt(getValues("skuCount"));

                                                            // console.log("quantityCheck, minimum order qty", qty, getMinOrderQuantity(parsedSkuCount));

                                                            if (!qty || qty === 0) {
                                                                return language.translateLabel('4d56851c-d414-487c-9ee3-77cb75e52101', 'A pouch quantity is required.');
                                                            }

                                                            if (qty < getMinOrderQuantity(parsedSkuCount)) {
                                                                return language.translateLabel('28dcbbdf-e937-4c50-b176-d5f9b21885fd', 'The minimum pouch quantity is:') + ' ' + getMinOrderQuantity(parsedSkuCount);
                                                            }
                                                            return true;
                                                        }
                                                    }
                                                }}
                                            />
                                            <AuthenticatedTemplate>
                                                <button
                                                    className={`btn btn-secondary text-action-container-button add`}
                                                    onClick={(e) => {
                                                        //console.log("add to quote placeholder");
                                                        e.preventDefault();
                                                        setUserSelectedQuantity(parseInt(getValues("quantity")));
                                                        getEstimate(e);
                                                    }}
                                                    disabled={!isValid}
                                                >
                                                    <span className='btn-text'>{language.translateLabel('cc6d9072-4c57-48d2-9cb3-867be029f308', 'Calculate Price', 'Button')}</span>
                                                </button>
                                            </AuthenticatedTemplate>
                                        </div>
                                    </div>
                                    <div>
                                        {errors && errors.quantity &&
                                            <p className="alert">{errors.quantity.message}</p>
                                        }
                                    </div>
                                </div>

                            </div>

                            <AuthenticatedTemplate>
                                <div className='section'>

                                    {priceQuantityBreakdown.length > 0 &&
                                        <div className="estimateList">
                                            <h3>{language.translateLabel('293de6ab-f6d4-42a6-8389-2cc2a9657857', 'Choose Your Quantity')}</h3>

                                            <SkuQuantitySelection
                                                userSelectedQuantity={userSelectedQuantity}
                                                skuQuantityBreakdown={priceQuantityBreakdown}
                                                pricesLoaded={loaded}
                                                quantitySelectionChanged={onQuantitySelected}
                                                onRemoveClicked={onQuantityRemoved}
                                                minSellablePrice={minSellablePrice}
                                            />
                                            <p className="notice small">*{language.translateLabel('600d6c99-5a40-4897-afda-4f6585269387', 'Displayed prices are estimated prices until artwork is finalized')}</p>
                                        </div>
                                    }
                                </div>
                            </AuthenticatedTemplate>
                            {product && convertingMethod && material &&
                                <button className='btn btn-tertiary-outline back' onClick={() => { onBackButtonClicked(); }}><span className='btn-text'>{language.translateLabel('48cda936-8f88-4703-a02c-69648eb3fa2a', 'Back', 'Button')}</span></button>
                            }
                            <AuthenticatedTemplate>
                                {getNextButton()}
                            </AuthenticatedTemplate>
                            <UnauthenticatedTemplate>
                                <button
                                    data-testid="button-sign-up"
                                    className={`btn btn-secondary pull-right next`}
                                    disabled={!isValid}
                                    onClick={(e) => {
                                        //console.log("add to quote placeholder");
                                        e.preventDefault();
                                        // console.log("fire", errors);
                                        // handleSubmit(async (data, errors) => await onSubmitOffer(data, errors));
                                        onSignupClicked(e);
                                    }}
                                >
                                    <span className='btn-text'>{language.translateLabel('f45bee7c-8acb-43eb-aa1c-ae379a59ca9b', 'Sign Up', 'Button')}</span>
                                </button>
                            </UnauthenticatedTemplate>
                            {/* <UnauthenticatedTemplate>
                                <button type="button" className="btn btn-secondary pull-right next" disabled={!priceQuantityBreakdown.length > 0 || !selectedQuantity} onClick={(e) => onSignupClicked(e)}><span className='btn-text'>{language.translateLabel('67faab93-8201-40e5-9261-1db130febea3', 'Proceed to Checkout', 'Button')}</span></button>
                            </UnauthenticatedTemplate> */}
                        </form>
                    </Page>
                : ''
            }
        </div>
    );
};
