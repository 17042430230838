import React, { useEffect, useContext, useState, useRef } from "react";
import { Page } from "../../core/Page";
import { Popup } from '../../core/Popup';
import { SkuQuantitySelection } from "../../components/SkuQuantitySelection";
import { Language } from '../../infrastructure/Languages';
import { GenericFormReadonlyInput } from "../../core/GenericFormReadonlyInput";

import "../Features.css";
import "../Skus.css";

export const QuantitySelectionComponent = ({ estimation, skuCount, onSubmit, onCancel }) => {

    const popup = useRef(null);
    const language = useContext(Language);

    const [priceQuantityBreakdownList, setPriceQuantityBreakdownList] = useState([]);
    const [selectedPriceQuantityBreakdown, setSelectedPriceQuantityBreakdown] = useState();
    const [userSelectedQuantity, setUserSelectedQuantity] = useState(0);
    const [canProceed, setCanProceed] = useState(false);
    const [popupMessage, setPopupMessage] = useState(null);
    const [minSellablePrice, setMinSellablePrice] = useState();

    const estimates = estimation.estimates.estimate;

    useEffect(() => {
        initData();
    }, []);

    const initData = () => {
        var estimate = estimates[4];
        var formattedEstimatesIncludingSavings = formatQuantities(estimates, estimation.minSellablePrice);
        var minSellable = estimation.minSellablePrice ? parseFloat(estimation.minSellablePrice) : 0;
        setMinSellablePrice(minSellable);
        estimate.auditId = estimation.auditId;
        estimate.currencyConversionRate = estimation.currencyConversionRate;
        setCanProceed(parseFloat(estimate.price.totalPrice.amount) >= minSellable);

        var priceBreakdown = [];
        formattedEstimatesIncludingSavings.forEach((est) => {
            priceBreakdown.push(est);
        })
        priceBreakdown.sort((a, b) => (a.orderedQty > b.orderedQty) ? 1 : -1);
        setSelectedPriceQuantityBreakdown(estimates[4]);
        setUserSelectedQuantity(estimates[4].orderedQty)
        setPriceQuantityBreakdownList(priceBreakdown);
    }

    const calculateSavings = (originalPrice, price2) => {
        var percentSavings = parseFloat(((originalPrice - price2) / originalPrice) * 100).toFixed(0);
        return Math.abs(percentSavings);
    }

    const formatQuantities = (quantityBreaks, minSellablePrice) => {
        var original = quantityBreaks[0].price.unitPrice.amount;

        var formattedQuantities = [];
        minSellablePrice = minSellablePrice ? parseFloat(minSellablePrice) : 0;
        quantityBreaks.forEach((quantity) => {
            if (minSellablePrice >= 0) {
                var canSell = parseFloat(quantity.price.unitPrice.amount * quantity.orderedQty) >= minSellablePrice;
                var savingsPercent = calculateSavings(original, quantity.price.unitPrice.amount);
                quantity.canSell = canSell;
                quantity.savingsPercentage = savingsPercent;
            }
            formattedQuantities.push(quantity);
        })
        return formattedQuantities;
    }

    const onQuantitySelected = (data) => {
        setUserSelectedQuantity(data);
        var priceBreakdown = priceQuantityBreakdownList.find(x => x.orderedQty === parseInt(data));
        setSelectedPriceQuantityBreakdown(priceBreakdown);
        setCanProceed(priceBreakdown.canSell);
    }

    const onSubmitClick = async () => {
        if (onSubmit)
            onSubmit(selectedPriceQuantityBreakdown.orderedQty);
    }

    const onCancelClick = () => {
        if (onCancel)
            onCancel();
    }

    return (
        <div className='skuPage'>
            <Popup className='selectionConflictMessage' closeOnClickAway={true} ref={popup}>
                <p className='message'>{popupMessage}</p>
            </Popup>
            <Page className="skusPage">
                <div className='numberEntry'>
                    <div className='section'>
                        <div className='inline'>
                            <h3>{language.translateLabel('895bb603-71f7-4f81-b4e5-5fa7800d1175', 'How many different SKUs will be using this package?')}</h3>
                            <GenericFormReadonlyInput
                                name="skuCount"
                                value={skuCount}
                                inputstyle="text-action-container-textbox-sm"
                            />

                        </div>
                    </div>

                    <div className='section'>
                        <div className='inline'>
                            <h3>{language.translateLabel('a231544b-e8f3-483d-a1fb-3448c95690e5', 'How many pouches would you like?')}</h3>
                            <div className="text-action-container">
                                <GenericFormReadonlyInput
                                    name="quantity"
                                    value={estimates[4]?.orderedQty}
                                />
                            </div>
                        </div>
                    </div>

                </div>

                <div className='section'>

                    {priceQuantityBreakdownList.length > 0 &&
                        <div className="estimateList">
                            <h3>{language.translateLabel('293de6ab-f6d4-42a6-8389-2cc2a9657857', 'Choose Your Quantity')}</h3>
                            <SkuQuantitySelection userSelectedQuantity={userSelectedQuantity} skuQuantityBreakdown={priceQuantityBreakdownList} quantitySelectionChanged={onQuantitySelected} minSellablePrice={minSellablePrice} />
                            <p className="notice small">*{language.translateLabel('600d6c99-5a40-4897-afda-4f6585269387', 'Displayed prices are estimated prices until artwork is finalized')}</p>
                        </div>
                    }
                </div>
                <button type="button" data-testid="button-go-back" className='btn btn-tertiary-outline back' onClick={() => { onCancelClick(); }}><span className='btn-text'>{language.translateLabel('48cda936-8f88-4703-a02c-69648eb3fa2a', 'Back', 'Button')}</span></button>
                <button type="button" data-testid="button-proceed-to-checkout" className="btn btn-secondary pull-right next" onClick={() => { onSubmitClick() }} disabled={!canProceed}><span className='btn-text'>{language.translateLabel('67faab93-8201-40e5-9261-1db130febea3', 'Proceed to Checkout', 'Button')}</span></button>
            </Page>
        </div>
    );
};
