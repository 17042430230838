import { useEffect, useState } from "react";
import { ApiDataAccess } from "../infrastructure/ApiDataAccess";

export const CmsArticle = ({ articleName }) => {
    const dataAccess = new ApiDataAccess("/api/application");
    const [article, setArticle] = useState(null);

    useEffect(() => {
        const loadArticle = async () => {
            var data = await dataAccess.get(`/cms/${articleName}`);
            var article = {
                name: data.name,
                html: data.html,
            };
            setArticle(article);
        };
        loadArticle();
    }, []);

    return (
        <div>
            <div className="popup-header">
                <div>
                    <div dangerouslySetInnerHTML={{ __html: article?.name }}></div>
                </div>
            </div>
            <div className="content popup-content">
                <div dangerouslySetInnerHTML={{ __html: article?.html }}></div>
            </div>
        </div>
    );
};
