import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { Popup } from '../../core/Popup';
import { Translation } from "../../core/Translation";
import { SecureApiDataAccess } from "../../infrastructure/SecureApiDataAccess";
import { SkuSelectionComponent } from "./SkuSelectionComponent";


export const ReorderSkuSelection = ({ }) => {

    let { globalOrderId } = useParams();

    const popup = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const [orderDetails, setOrderDetails] = useState();

    const salesforceAccountId = location.state.salesforceAccountId;
    const emailAddress = location.state.emailAddress;

    useEffect(() => {
        const init = async () => {
            await loadConfig();
        }

        if (!!salesforceAccountId)
            init();
        else
            goBack();

    }, []);

    const loadConfig = async () => {
        try {
            var secureDataAccess = new SecureApiDataAccess('/api/reorder/');
            var response = await secureDataAccess.get(`order/${globalOrderId}/configuration`);
            console.log("Data", response);
            setOrderDetails(response.data);
        }
        catch (ex) {
            popup.current.show();
        }
    }

    const goBack = () => {
        navigate(-1);
    }

    const goToQuantitySelectionPage = async (selectedSkus) => {
        navigate("quantity", {
            state:
            {
                emailAddress: emailAddress,
                selectedSkus: selectedSkus,
                salesforceAccountId: salesforceAccountId,
                globalOrderId: globalOrderId
            }
        });
    }

    return (
        <div>
            {
                orderDetails &&
                <SkuSelectionComponent orderDetails={orderDetails} onSubmit={(selectedSkus) => { goToQuantitySelectionPage(selectedSkus) }} onCancel={() => { goBack() }} />
            }
            <Popup closeOnClickAway={true} onClose={goBack} ref={popup}>
                <div className='contentSection'>
                    <Translation id='c9ddf956-3950-4cec-a051-fdd7cf641e0d' defaultMessage='No reorderable items found for the selected order' category='Label' />
                </div>
            </Popup>
        </div >
    )
}