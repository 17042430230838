import React, { useEffect, useState, useContext } from "react";
import { ApiDataAccess } from "../infrastructure/ApiDataAccess";
import { Language } from "../infrastructure/Languages";

export const Translation = ({ id, sub, defaultMessage, category, values }) => {
    const language = useContext(Language);
    const key = `${id}`;
    const keyVariant = sub ? parseInt(sub) : 0;
    const cat = category ? category : "label";
    // const [translation, setTranslation] = useState(defaultMessage);

    const getFormattedText = () => {

        var txt = language.translateDomain(key, keyVariant, defaultMessage, cat);
        if (values) {
            Object.keys(values).forEach((key) => {
                var token = "{" + key + "}";
                txt = txt.replace(token, values[key]);
            });
        }
        return txt;
    };
    //     useEffect(() => {
    //     var txt = language.translateDomain(key, keyVariant, defaultMessage, cat);
    //     if (values) {
    //         Object.keys(values).forEach((key) => {
    //             var token = "{" + key + "}";
    //             txt = txt.replace(token, values[key]);
    //         });
    //     }
    //     setTranslation(txt);
    //     console.log('translation ran');
    // }, []);

    // return <span>{translation}</span>;
    return <span>{getFormattedText()}</span>;
};
