import React, { useState } from 'react';
import { ApiDataAccess } from './ApiDataAccess';

export const CmsArticle = props => {

    const [article, setArticle] = useState(null);

    const getArticleAsync = (name) => {
        return new Promise((resolve, reject) => {
            var dataAccess = new ApiDataAccess('api/application');
            dataAccess.get(`/cms/${name}`)
                .then(article => {
                    resolve(article);
                })
                .catch(ex => reject(ex));
        });
    }

    getArticleAsync(props.name)
        .then(article => {
            console.log(article);
            setArticle(article);
        });

    return (
        <div className='article'>
            {article &&
                <div>
                    <div dangerouslySetInnerHTML={{ __html: article.html }} />
                </div>
            }
        </div>
    )
}