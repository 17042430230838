import React, { useState, useEffect, useRef, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Check } from 'react-feather';
import { useTracking } from 'react-tracking';
import { ShoppingBreadcrumbs, OrderReference } from '../../core/Breadcrumbs';
import { Card } from '../../core/Card';
import { Popup } from '../../components/offer/Popup';
import { Translation } from '../../core/Translation';
import { Textbox } from '../../components/offer/Textbox';
import { ApiDataAccess } from '../../infrastructure/ApiDataAccess';
import { CapabilitiesContext } from "../../infrastructure/Contexts";
import { CapabilityName, OrderType } from "../../infrastructure/Constants";
import OrderPlacementApi from '../../infrastructure/OrderPlacementApi';
import { useGlobalOfferContext } from '../../infrastructure/GlobalOfferId';
import './ArtworkPayment.css';

export const CheckoutLandingStep = ({ analyticsMetadata, offer, orderReference, status, orderIntent, globalOfferId }) => {
    const capabilitiesContext = useContext(CapabilitiesContext);
    const loader = capabilitiesContext.getCapability(CapabilityName.Loader);
    const orderPlacementApi = new OrderPlacementApi("/");
    const paymentPopupRef = useRef(null);
    const artworkPopupRef = useRef(null);
    const isReorder = offer && !!offer.reorderDetails;

    const { trackEvent } = useTracking({ page: "Artwork Payment Page" });

    const [cookies, setCookie] = useCookies(['visitorId']);
    const [visitorId, setVisitorId] = useState(null);

    const { handleSubmit, reset, watch, formState: { isSubmitting, isSubmitSuccessful } } = useForm({
        defaultValues: {
            artworkComplete: false,
            paymentComplete: false
        }
    })
    const { offerContext } = useGlobalOfferContext(globalOfferId);

    const artworkCompleteWatch = watch("artworkComplete");
    const paymentCompleteWatch = watch("paymentComplete");


    useEffect(() => {
        const load = async () => {
            await setIndicators();
        }

        var id = cookies.visitorId;
        if (id) {
            setVisitorId(id);
        }
        if (status !== 'success' || !offerContext) {
            return;
        }
        load();
    }, [offer, orderIntent, offerContext]);

    useEffect(() => {
        if (isSubmitting || status !== 'success') {
            loader.show();
        }
        else {
            loader.hide();
        }
    }, [isSubmitting, status]);

    /*Clean up the lingering modal until it can be slightly refactored to unload correctly */
    useEffect(() => {
        return () => {
            loader.hide();
        };
    }, []);

    useEffect(() => {
        if (isReorder && paymentCompleteWatch && !artworkCompleteWatch)
            goToArtworkPage();
    }, [paymentCompleteWatch]);

    const setIndicators = async () => {

        var hasArtwork = await isArtworkUploadComplete();
        var hasPayment = orderIntent.paymentStateId === 'COMPL' || orderIntent.paymentStateId === 'AWCHK';

        reset({
            artworkComplete: hasArtwork,
            paymentComplete: hasPayment
        });
    }


    const isArtworkUploadComplete = async () => {

        if (orderReference && orderReference.orderType === OrderType.TestRoll) {
            return true;
        }

        if (isReorder)
        {
            return offerContext.reorderDetailsCheckout?.artworkApprovalWaived;
        }

        var apiResponse = await orderPlacementApi.getArtworkSkus(globalOfferId);
        var skus = apiResponse.response;

        if (skus && skus.length > 0) {
            var sku = skus ? skus.find(x => x.hasArtworkData == false) : null;
            return sku ? false : true;
        }
        return false;
    }

    const goToArtworkPage = () => {
        document.location = `/offer/${globalOfferId}/artwork`;
    }

    const isArtworkButtonDisabled = () => {
        if (orderReference.orderType === OrderType.TestRoll)
            return true;
        if (isReorder && artworkCompleteWatch)  // reorder case when artwork approval has been altrady waived
            return true;
        return false;
    }

    const submitOrder = async (data, errors) => {

        var submitOrderResponse = await orderPlacementApi.submitForOrderPlacement(globalOfferId);

        console.log("submit order response", submitOrderResponse);


        //NOTE: The code below here can be used when developing locally to simulate a call to the back end for testing
        // const delay = ms => new Promise(res => setTimeout(res, ms));
        // await delay(5000);

        trackEvent({ ecommerce: null });  // Clear the previous ecommerce object.

        trackEvent({
            event: 'purchase',
            ecommerce: {
                items: [
                    {

                        item_id: offer.product.id,
                        item_name: offer.product.value,
                        currency: offer.plant.currency,
                        //  index: quoteCount,            //the nth quote added, starting at 1
                        unit_price: offer.calculationBreakdown[0].price.unitPrice.amount,
                        item_category: offer.productGroup.value,  //what packaging category
                        item_category2: analyticsMetadata.industryCategoryName,      //what packaging sub-category
                        item_category3: offer.convertingMethod.componentType.value,     //pouch type
                        item_category4: offer.dimensions.width + " x " + offer.dimensions.length,      // width x length
                        price: offer.calculationBreakdown[0].price.totalPrice.amount,
                        quantity: offer.calculationBreakdown[0].quantityBreak,
                        skuCount: offer.skuCount
                    }
                ],
                transaction_id: orderReference.orderNumber,
                value: orderIntent.intentRequest.orderIntentTotals.grandTotalAmount,     //value is the price from items array minus discount amount
                //  tax: orderIntent.orderIntentTotals.
                shipping: orderIntent.intentRequest.orderIntentTotals.shippingAmount,
                currency: offer.plant.currency
            },
            userId: cookies.visitorId
        });



        document.location = `/offer/${globalOfferId}`;
    }

    //const LoadingSpinnerCard = () => (
    //    <Card>
    //        {/*<div>*/}
    //        {/*    <h3><Translation id='1ea06ef6-b171-4b92-bbf8-73e95db7a8b8' defaultMessage='Please do not navigate away from this screen.' category='Label' /></h3>*/}
    //        {/*</div>*/}
    //        {/*<div><Translation id='497b3f42-dc82-4d5d-bc19-07861764df4e' defaultMessage='It will just take a few seconds to load...' category='Label' /></div>*/}
    //    </Card>
    //);

    const renderStep = () => {
        if (isSubmitting || isSubmitSuccessful) {
            return (
                <div></div>
            )
        }
        else {
            return status === 'success' && (
                <div>
                    <ShoppingBreadcrumbs active={8} ignore={offer?.salesChannel == 'Estimator' ? 4 : 0} />
                    <OrderReference orderReference={orderReference} />

                    <Card title={<Translation id='43a4782e-dc69-4f54-8941-ae7ab4b0104c' defaultMessage='You are almost done!' category='Card Title' />} subtitle={<Translation id='cbb7544f-16e0-469d-bd28-f6074bf1723d' defaultMessage='There are two more steps remaining before we can manufacture your order - we need to receive a payment and we also need your artwork.' category='Card Sub Title' />}>
                        <button className='btn btn-primary btn-square'
                            data-testid="order-placement-pay-button"
                            disabled={paymentCompleteWatch} onClick={() => { paymentPopupRef.current.show(); }}>{paymentCompleteWatch && <div className='state'><Check className='icon' /></div>} <Translation id='0f2f8453-c3c3-4fdc-b8f4-c94f994f75b6' defaultMessage='Payment' category='Button' /></button>
                        <button
                            className='btn btn-primary btn-square'
                            data-testid="order-placement-artwork-upload-button"
                            disabled={isArtworkButtonDisabled()}
                            onClick={() => { !isReorder ? artworkPopupRef.current.show() : goToArtworkPage() }}>
                            {artworkCompleteWatch && <div className='state'><Check className='icon' /></div>}
                            {
                                !isReorder ?
                                <Translation id='b217b91e-805d-4d73-9939-a9f6ded547f8' defaultMessage='Upload Artwork' category='Button' /> :
                                <Translation id='2fdc2ac1-1fae-4e40-a60e-cdb244c649d3' defaultMessage='Review Artwork' category='Button' />
                            }
                        </button>
                    </Card>

                    {artworkCompleteWatch && paymentCompleteWatch &&
                        <div>
                            <form onSubmit={handleSubmit(submitOrder)}>
                                <div className='alert info'><div className="alert-content"><Translation id='b9af3cae-1d6d-42e2-8fc1-21c0a9721783' defaultMessage='Please click the Submit Order button below to place your order now.' category='Label' /></div></div>
                                <button data-testid="order-placement-checkout-landing-submit-order-button" type="submit" disabled={isSubmitting} className="btn btn-secondary pull-right next" onClick={() => {
                                    handleSubmit(async (data, errors) => submitOrder(data, errors));
                                }}>Submit Order</button>
                            </form>
                        </div>
                    }

                    <Popup ref={paymentPopupRef}>
                        <PaymentPopupWizard globalOfferId={globalOfferId} salesForceAccountId={offer?.salesForceAccountId} salesContact={offer.salesContact} popup={paymentPopupRef.current} orderPlacementApi={orderPlacementApi} trackEvent={trackEvent} analyticsMetadata={analyticsMetadata} onCloseClick={() => { paymentPopupRef.current.close() }} />
                    </Popup>

                    <Popup ref={artworkPopupRef}>
                        <ArtworkPopupWizard data={offer} popup={artworkPopupRef.current} orderPlacementApi={orderPlacementApi} salesForceAccountId={offer.salesForceAccountId} salesContact={offer.salesContact} trackEvent={trackEvent} analyticsMetadata={analyticsMetadata} globalOfferId={globalOfferId} onCloseClick={() => { artworkPopupRef.current.close() }} />
                    </Popup>

                </div>
            );
        }
    }

    return renderStep();
}

export const ArtworkPopupWizard = ({ globalOfferId, salesForceAccountId, salesContact, orderPlacementApi, analyticsMetadata, trackEvent, onCloseClick }) => {

    const capabilitiesContext = useContext(CapabilitiesContext);
    const loader = capabilitiesContext.getCapability(CapabilityName.Loader);

    const Steps = {
        Init: 0,
        ContactEmailInput: 1,
        EmailSentConfirmation: 2,
    };

    const [step, setStep] = useState(Steps.Init);
    const [valid, setValid] = useState(false);
    const [email, setEmail] = useState('');


    const onEmailEntered = (txt, valid) => {
        setValid(valid);
        setEmail(txt);
    }

    const onSomeoneElseWillDoButtonClick = async () => {
        loader.show();
        var dataAccess = new ApiDataAccess('api/Contacts');
        var contacts = await dataAccess.get(`/artwork/${salesForceAccountId}`);


        if (contacts && contacts.length > 0 && contacts[0].email) {
            setEmail(contacts[0].email);
            setValid(true);
        } else {
            setEmail('');
            setValid(false);
        }
        loader.hide();
        setStep(Steps.ContactEmailInput);
    }


    const onSendClick = async () => {
        loader.show();
        var fromEmail = salesContact && salesContact.email
            ? salesContact.email
            : 'epacappsupport@epacllc.com';

        await orderPlacementApi.sendArtworkRequestEmail(globalOfferId, fromEmail, email);
        trackEvent({
            event: 'ordering_tool_interactions',
            eventProps: {
                'step': 'placement_4',
                'product_category': analyticsMetadata.industryName,
                'product_sub_category': analyticsMetadata.industryCategoryName,
                'selection': "send_artwork_request_email",
                'element': email
            },
            userId: analyticsMetadata?.visitorId
        });
        loader.hide();
        setStep(Steps.EmailSentConfirmation);
    }

    // eslint-disable-next-line default-case
    switch (step) {
        case Steps.Init:
            return (
                <>
                    <div className='content'>
                        <h3><Translation id='8ef5177e-8036-40df-89e0-bb5f88c19903' defaultMessage='Artwork Upload' category='Label' /></h3>
                        <p><Translation id='cf8a044c-46f1-4fe3-b297-a5abcda931aa' defaultMessage='How would you like to provide your artwork?' category='Label' /></p>
                    </div>
                    <button
                        data-testid="order-placement-someone-else-upload-artwork-button"
                        className="button left" onClick={() => {
                            trackEvent({
                                event: 'ordering_tool_interactions',
                                eventProps: {
                                    'step': 'placement_4',
                                    'product_category': analyticsMetadata.industryName,
                                    'product_sub_category': analyticsMetadata.industryCategoryName,
                                    'selection': "someone_else_will_pay",
                                },
                                userId: analyticsMetadata?.visitorId
                            });
                            onSomeoneElseWillDoButtonClick();
                        }}><Translation id='0d202d56-da4a-481c-937c-c0d28038251f' defaultMessage='Someone else will do' category='Button' /></button>
                    <button
                        data-testid="order-placement-upload-artwork-myself-button"
                        className="button right" onClick={() => { document.location = `/offer/${globalOfferId}/artwork` }}><Translation id='899bf086-a908-467b-8a98-faf2ab336535'
                            defaultMessage='I will provide it myself' category='Button' /></button>
                </>
            );
        case Steps.ContactEmailInput:
            return (
                <>
                    <div className='content'>
                        <h3><Translation id='8ef5177e-8036-40df-89e0-bb5f88c19903' defaultMessage='Artwork Upload' category='Label' /></h3>
                        <p><Translation id='9162c06d-58a5-47d9-ac5a-45e3b50f784d' defaultMessage='Please enter the email address of the artwork contact:' category='Label' /></p>
                        <Textbox value={email} onChange={(txt, valid) => { onEmailEntered(txt, valid) }} regex="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$" type='email' placeholder='Artwork contact email address'></Textbox>
                    </div>
                    <button className="button left" disabled={!valid} onClick={() => { onSendClick() }}><Translation id='9c4c4ae0-5705-4869-a601-117505892fc7' defaultMessage='Send' category='Button' /></button>
                    <button className="button right" onClick={() => { document.location = `/offer/${globalOfferId}/artwork` }}><Translation id='899bf086-a908-467b-8a98-faf2ab336535' defaultMessage='I will provide it myself' category='Button' /></button>
                </>
            );
        case Steps.EmailSentConfirmation:
            return (
                <>
                    <div className='content'>
                        <h3><Translation id='8ef5177e-8036-40df-89e0-bb5f88c19903' defaultMessage='Artwork Upload' category='Label' /></h3>
                        <p><Translation id='8790b967-5829-4a73-974a-999be037b997' defaultMessage='An artwork request has been sent to:' category='Label' /> {email}.</p>
                        <p><Translation id='ace305e6-f1be-4355-a666-2d2fa5fb9cdc' defaultMessage='Your order requires artwork before it can be manufactured.' category='Label' /></p>
                    </div>
                    <button className="button" onClick={() => { onCloseClick() }}><Translation id='0fcfd064-0c9d-4027-a55b-7f2ad8fb1cf0' defaultMessage='Close' category='Button' /></button>
                </>
            );
    }
}

export const PaymentPopupWizard = ({ salesForceAccountId, globalOfferId, salesContact, orderPlacementApi, trackEvent, analyticsMetadata, onCloseClick }) => {

    const capabilitiesContext = useContext(CapabilitiesContext);
    const loader = capabilitiesContext.getCapability(CapabilityName.Loader);

    const navigate = useNavigate();
    const Steps = {
        Init: 0,
        PayeeEmailInput: 1,
        EmailSentConfirmation: 2,
    };

    const [step, setStep] = useState(Steps.Init);
    const [valid, setValid] = useState(false);
    const [email, setEmail] = useState('');


    const onEmailEntered = (txt, valid) => {
        setValid(valid);
        setEmail(txt);
    }

    const onSomeoneElseWillPayButtonClick = async () => {
        loader.show();
        var res = await orderPlacementApi.getBillingAddress(salesForceAccountId);

        if (res.hasSuccess && res.response && res.response.email) {
            setEmail(res.response.email)
            setValid(true);
        } else {
            setEmail('');
            setValid(false);
        }
        loader.hide();

        setStep(Steps.PayeeEmailInput);
    }

    const onSendClick = async () => {
        loader.show();
        var fromEmail = salesContact && salesContact.email
            ? salesContact.email
            : 'epacappsupport@epacllc.com';

        await orderPlacementApi.sendPaymentRequestEmail(globalOfferId, fromEmail, email);
        trackEvent({
            event: 'ordering_tool_interactions',
            eventProps: {
                'step': 'placement_4',
                'product_category': analyticsMetadata.industryName,
                'product_sub_category': analyticsMetadata.industryCategoryName,
                'selection': "send_payment_request_email",
                'element': email
            },
            userId: analyticsMetadata?.visitorId
        });
        loader.hide();
        setStep(Steps.EmailSentConfirmation);
    }

    switch (step) {
        case Steps.Init:
            return (
                <>
                    <div className='content'>
                        <h3><Translation id='7550215c-f5e0-4b6e-81ff-8bef02f3c409' defaultMessage='Payment' category='Label' /></h3>
                        <p><Translation id='7ef73bc9-6e23-457d-88bd-0fc707a667ef' defaultMessage='How would you like to pay for this order?' category='Label' /></p>
                    </div>
                    <button
                        data-testid="order-placement-someone-else-will-pay-button"
                        className="button left" onClick={() => {
                            trackEvent({
                                event: 'ordering_tool_interactions',
                                eventProps: {
                                    'step': 'placement_4',
                                    'product_category': analyticsMetadata.industryName,
                                    'product_sub_category': analyticsMetadata.industryCategoryName,
                                    'selection': 'someone_else_will_pay',
                                },
                                userId: analyticsMetadata?.visitorId
                            });
                            onSomeoneElseWillPayButtonClick();
                        }}><Translation id='bd352eb9-4199-4620-8342-e1dbdca06475' defaultMessage='Someone else will pay' category='Button' /></button>
                    <button
                        data-testid="order-placement-will-pay-myself-button"
                        className="button right" onClick={() => {

                            trackEvent({
                                event: 'ordering_tool_interactions',
                                eventProps: {
                                    'step': 'placement_4',
                                    'product_category': analyticsMetadata.industryName,
                                    'product_sub_category': analyticsMetadata.industryCategoryName,
                                    'selection': 'pay_myself',
                                },
                                userId: analyticsMetadata?.visitorId
                            });

                            navigate(`/offer/${globalOfferId}/pay`, {
                                state: {
                                    fromWeb: true
                                }
                            });
                        }}><Translation id='f79e04a0-f8f8-4a3a-b772-1715f2d1ee8c' defaultMessage='I will pay myself' category='Button' /></button>
                </>
            )
        case Steps.PayeeEmailInput:
            return (
                <>
                    <div className='content'>
                        <h3><Translation id='7550215c-f5e0-4b6e-81ff-8bef02f3c409' defaultMessage='Payment' category='Label' /></h3>
                        <p><Translation id='61b55762-bcef-4f3e-b559-9895e7b3c2f4' defaultMessage='Please enter the email address of the payee:' category='Label' /></p>
                        <Textbox onChange={(txt, valid) => { onEmailEntered(txt, valid) }} value={email} regex="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$" type='email' placeholder='Payee email address'></Textbox>
                    </div>
                    <button className="button left" disabled={!valid} onClick={() => { onSendClick() }}><Translation id='9c4c4ae0-5705-4869-a601-117505892fc7' defaultMessage='Send' category='Button' /></button>
                    <button className="button right" onClick={() => {
                        navigate(`/offer/${globalOfferId}/pay`, {
                            state: {
                                fromWeb: true
                            }
                        });
                    }}><Translation id='f79e04a0-f8f8-4a3a-b772-1715f2d1ee8c' defaultMessage='I will pay myself' category='Button' /></button>
                </>
            )
        case Steps.EmailSentConfirmation:
            return (
                <>
                    <div className='content'>
                        <h3><Translation id='7550215c-f5e0-4b6e-81ff-8bef02f3c409' defaultMessage='Payment' category='Label' /></h3>
                        <p><Translation id='55d6cd55-42bc-4c4b-bc85-da28ef9186a8' defaultMessage='A payment request has been sent to:' category='Label' /> {email}.</p>
                        <p><Translation id='9c3bbbc3-0888-46d5-a0ef-192e1329f4d9' defaultMessage='Your order requires a payment before it can be manufactured.' category='Label' /></p>
                    </div>
                    <button className="button" onClick={() => { onCloseClick() }}><Translation id='0fcfd064-0c9d-4027-a55b-7f2ad8fb1cf0' defaultMessage='Close' category='Button' /></button>
                </>
            )
        default:
            break;
    }
}