import React, { Component } from 'react';

export const SectionLabel = ({
    description,
    children
}) => {
    const displayName = SectionLabel.name;

    return (
        <div className='sectionLabel'>
            <div className='description'>{description}</div>
            <div className='action'>{children}</div>
        </div>
    );
}