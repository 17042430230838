import React, { useState, useImperativeHandle } from "react";
import { forwardRef } from "react";
import { BackSplash } from "../../core/BackSplash";

import './Popup.css';

export const Popup = forwardRef((props, ref) => {

    const [visible, setVisible] = useState(false);

    useImperativeHandle(ref, () => ({
        show: () => show(true),        
        close: () => show(false)
    }));

    const show = (visible) => {
        setVisible(visible);
    }

    const onClickBackSplash = () => {
        if (!props.preventClickAway) {
            show(false);
        }        
    }

    if (visible) {
        return (
            <div>
                <BackSplash onClick={() => { onClickBackSplash(); } } />
                <div className={ !props.isThumbnail ? "confirmation popup" : "thumbnail-popup"}>
                    {props.children}
                </div>
            </div>
        );
    }
});
