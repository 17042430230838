import { useMsal } from "@azure/msal-react";
import { Popup } from "./Popup";
import { loginRequest } from "../../authConfig";
import { forwardRef } from "react";
import { CmsArticle } from "../../core/CmsArticle";
import { useNavigate } from "react-router-dom";
import { Translation } from "../../core/Translation";

export const RegisterSignInPopup = forwardRef((props, ref) => {
    const { instance } = useMsal();
    const navigate = useNavigate();

    const createRedirectUri = () => {
        var uri = new URLSearchParams("");

        if (props.salesforceAccountId) {
            uri.append("sfid", props.salesforceAccountId);
        }

        if (props.redirectTo) {
            uri.append("to", encodeURIComponent(props.redirectTo));
        }

        return "/register/account?" + uri.toString();
    };

    const redirectUri = createRedirectUri();

    return (
        <Popup className="selectionConflictMessage" closeOnClickAway={true} ref={ref}>
            <CmsArticle articleName="sign-in-or-register" />
            <button
                className="button left"
                onClick={(e) => {
                    props?.onCloseClick();
                }}
            >
                <Translation id="599ab7e1-59a3-41cd-b114-f8d3b08b503e" defaultMessage="Later" category="Label" />
            </button>
            <button
                className="button right"
                onClick={(e) => {
                    console.log("login to", redirectUri);
                    instance
                        .loginPopup({
                            scopes: loginRequest.scopes,
                            redirectUri: "/register/account",
                            state: redirectUri
                        })
                        .then((loginResponse) => {
                            if (loginResponse) {
                                navigate(loginResponse.state, {
                                    replace: true,
                                });
                            }
                        })
                        .catch((error) => console.log("login redirect error", error));
                }}
            >
                <Translation id="e5fc8882-fda7-4967-a433-cb5e6a4a6c9a" defaultMessage="Sign In" category="Label" />
            </button>
        </Popup>
    );
});
